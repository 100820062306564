export type Route = {
  [key: string]: string
}

export const routes = {
  home: '/',
  cart: {
    base: '/cart',
  },
  account: {
    base: '/account',
    login: '/account/login',
    newPassword: '/account/new-password',
    register: '/account/register',
    orderHistory: {
      base: '/account/order-history',
      detail: '/account/order-history/[orderNumber]',
    },
    details: {
      base: '/account/details',
      address: '/account/details/address',
    },
  },
  contact: {
    base: '/contact',
  },
  checkout: {
    base: '/checkout',
    shipping: '/checkout/shipping',
    payment: '/checkout/payment',
  },
  productRegistration: {
    base: '/product-registration',
    results: '/product-registration/results',
    success: '/product-registration/success',
  },
  spareparts: {
    base: '/spareparts',
    results: '/spareparts/results',
  },
  wishlist: '/wishlist',
}
