import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect } from 'react'

import { Modal as ModalBase } from '@emico/modal'
import ModalBackdrop from '@emico/modal-backdrop'
import {
  ModalSegueFade,
  ModalSegueLeft,
  ModalSegueRight,
} from '@emico/modal-segue'
import { minWidth, maxWidth } from '@emico/styles'
import { H1, ButtonUnstyled } from '@emico/ui'

import CrossIcon from '../icons/CrossIcon'
import theme from '../theme'

const StyledModalBackdrop = styled(ModalBackdrop, {
  shouldForwardProp: (prop) =>
    !['showMobileBackdrop'].includes(prop.toString()),
})<{ showMobileBackdrop?: boolean }>`
  @media ${maxWidth('md')} {
    display: ${({ showMobileBackdrop }) => !showMobileBackdrop && 'none'};
  }
`

export const ModalBody = styled.section`
  background: ${theme.colors.white};
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  max-height: stretch;

  @media ${minWidth('lg')} {
    left: 50%;
    top: 50%;
    height: auto;
    max-height: 90vh;
    max-width: 1000px;
    transform: translate(-50%, -50%);
  }
`

const Header = styled('header', {
  shouldForwardProp: (prop) =>
    !['hasHeaderBackground'].includes(prop.toString()),
})<{ hasHeaderBackground?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media ${maxWidth('md')} {
    background-color: ${({ hasHeaderBackground }) =>
      hasHeaderBackground && theme.colors.backgroundDark};
    --color-text: ${({ hasHeaderBackground }) =>
      hasHeaderBackground && theme.colors.textLight};
    --color-icon: ${({ hasHeaderBackground }) =>
      hasHeaderBackground && theme.colors.textLight};
  }
`

export const TitleWrapper = styled.div`
  padding: ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    padding: ${theme.spacing.lg} ${theme.spacing.lg} ${theme.spacing.sm}
      ${theme.spacing['2xl']};
  }
`

const Title = styled(H1)`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.medium};

  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes['2xl']};
  }
`

const Subtitle = styled.p`
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.grayBrown};
  margin: 0;
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  margin-left: auto;
  padding: ${theme.spacing.lg};
`

const StyledCrossIcon = styled(CrossIcon)`
  font-size: 16px;
  color: var(--color-icon, ${theme.colors.grayDark});
`

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  padding: 0 ${theme.spacing.lg} ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    padding: 0 ${theme.spacing['2xl']} ${theme.spacing['2xl']};
  }
`

type ModalSegueProps = Pick<
  Props,
  'show' | 'segueType' | 'renderBeforeShow' | 'children'
>

const ModalSegue = ({ segueType, children, ...other }: ModalSegueProps) => {
  switch (segueType) {
    case 'left':
      return <ModalSegueLeft {...other}>{children}</ModalSegueLeft>
    case 'right':
      return <ModalSegueRight {...other}>{children}</ModalSegueRight>
    default:
      return <ModalSegueFade {...other}>{children}</ModalSegueFade>
  }
}

interface Props {
  show: boolean
  close(): void
  children: ReactNode
  title?: ReactNode
  subtitle?: ReactNode
  segueType?: 'fade' | 'left' | 'right'
  hasHeaderBackground?: boolean
  preventCloseOnRouteChange?: boolean
  showMobileBackdrop?: boolean
  renderBeforeShow?: boolean
  bottom?: ReactNode
}

const Modal = ({
  show,
  close,
  children,
  title,
  subtitle,
  segueType = 'fade',
  hasHeaderBackground = false,
  preventCloseOnRouteChange = false,
  showMobileBackdrop = false,
  renderBeforeShow = false,
  bottom,
  ...other
}: Props) => {
  const { events } = useRouter()

  // Close modal on route change, when preventCloseOnRouteChange prop is not passed as true
  useEffect(() => {
    if (!preventCloseOnRouteChange) {
      events.on('routeChangeStart', () => close())
    }
  }, [close, events, preventCloseOnRouteChange])

  return (
    <>
      <StyledModalBackdrop
        show={show}
        onClick={close}
        showMobileBackdrop={showMobileBackdrop}
      />

      <ModalSegue
        show={show}
        segueType={segueType}
        renderBeforeShow={renderBeforeShow}
      >
        <ModalBase close={close} show={show} hasFocusTrap={false} {...other}>
          <ModalBody>
            <Header hasHeaderBackground={hasHeaderBackground}>
              <TitleWrapper>
                {title && <Title>{title}</Title>}

                {subtitle && <Subtitle>{subtitle}</Subtitle>}
              </TitleWrapper>

              <StyledButtonUnstyled
                analyticsContext="modal"
                analyticsName="close"
                onClick={close}
              >
                <StyledCrossIcon />
              </StyledButtonUnstyled>
            </Header>

            <Content>{children}</Content>

            {bottom && bottom}
          </ModalBody>
        </ModalBase>
      </ModalSegue>
    </>
  )
}

export default Modal
